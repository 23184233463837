@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('/public/fonts/Inter-roman.var.woff2') format('woff2');
}
@font-face {
  font-family: 'spac3';
  src: url('/public/fonts/spac3.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

